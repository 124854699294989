@import './theme.scss';

//-----------------Checkboxes----------------------
.be-ch-countainer {
    position: relative;
    padding-left: 25px;
    padding-top: 1px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    ::after, ::before{
      box-sizing: unset !important;
    }
    line-height: normal !important;

}

.be-ch-countainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.be-ch-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: .2rem;
    outline: 0;
    
}

.be-ch-countainer:hover input ~ .be-ch-checkmark {
    background-color: #ccc;
}
.be-ch-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.be-ch-countainer input:checked ~ .be-ch-checkmark:after {
    display: block;
}

.be-ch-countainer .be-ch-checkmark:after {
    left: 7px;
    top: 4px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
@each $name, $color in $colors {
    .be-ch-countainer input:checked ~ .be-ch-checkmark-#{$name} {
        background-color: $color;
    } 
}

//-----------------Radio buttons----------------------
.be-ra-container {
    display: inline-block;
    position: relative;
    padding-left: 23px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.be-ra-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.be-ra-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}
.be-ra-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.be-ra-container input:checked ~ .be-ra-checkmark:after {
    display: block;
}

.be-ra-container .be-ra-checkmark:after {
        top: 7px;
        left: 7px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: white;
}
.be-ra-container:hover input ~ .be-ra-checkmark {
    background-color: #ccc;
}
@each $name, $color in $colors {
    .be-ra-container input:checked ~ .be-ra-checkmark-#{$name} {
        background-color: $color;
    }
}


// SELECT

.be-select {
    position: relative;
    display: inline-block;
    line-height: normal !important;
}

.be-select select {
    display: none;
}

.select-selected {
    background-color: white;
    border-radius: .2rem;
    border: .5px solid rgb($combo-border, .1);
    &:hover{
        border: .5px solid rgb($combo-border, .3);
    }
}

.select-selected:after {
    position: absolute;
    content: "";
    top: 16px;
    right: 10px;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: rgb(0, 0, 0) transparent transparent transparent;
}

.select-selected.select-arrow-active{
    border-radius: .5rem .5rem 0 0;
    border-color: white;
}

.select-selected.select-arrow-active:after {
    border-color: transparent transparent rgb(0, 0, 0) transparent;
    top: 10px;
}

.select-items div,.select-selected {
    color: #000000;
    padding: 8px 16px;
    cursor: pointer;
    user-select: none;
}

.select-items {
    position: absolute;
    background-color: white;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    border-radius: 0 0 .5rem .5rem;
    border-style: none;
}

.select-hide {
    display: none;
}

.select-items div:hover, .same-as-selected {
    background-color: rgba(0, 0, 0, 0.05);
}

//block

.blockDis{
    display: block;
}

//switch - credits to kemie
.switch {
  font-size: $font-size-base;
  position: relative;

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;

    + label {
      position: relative;
      top:2;
      min-width: calc(#{$switch-height} * 2);
      border-radius: $switch-border-radius;
      height: $switch-height;
      line-height: $switch-height;
      display: inline-block;
      cursor: pointer;
      outline: none;
      user-select: none;
      vertical-align: middle;
      text-indent: calc(calc(#{$switch-height} * 2) + .5rem);
    }

    + label::before,
    + label::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(#{$switch-height} * 2);
      bottom: 0;
      display: block;
    }

    + label::before {
      right: 0;
      background-color: $switch-bg;
      border-radius: $switch-border-radius;
      transition: $switch-transition;
    }

    + label::after {
      top: $switch-thumb-padding;
      left: $switch-thumb-padding;
      width: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      height: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      border-radius: $switch-thumb-border-radius;
      background-color: $switch-thumb-bg;
      transition: $switch-transition;
    }

    &:checked + label::before {
      background-color: $switch-checked-bg;
    }

    &:checked + label::after {
      margin-left: $switch-height;
    }

    &:focus + label::before {
      outline: none;
      box-shadow: $switch-focus-box-shadow;
    }

    &:disabled + label {
      color: $switch-disabled-color;
      cursor: not-allowed;
    }

    &:disabled + label::before {
      background-color: $switch-disabled-bg;
    }
  }

  // Small variation
  &.switch-sm {
    font-size: $font-size-sm;

    input {
      + label {
        min-width: calc(#{$switch-height-sm} * 2);
        height: $switch-height-sm;
        line-height: $switch-height-sm;
        text-indent: calc(calc(#{$switch-height-sm} * 2) + .5rem);
      }

      + label::before {
        width: calc(#{$switch-height-sm} * 2);
      }

      + label::after {
        width: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
        height: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
      }

      &:checked + label::after {
        margin-left: $switch-height-sm;
      }
    }
  }

  // Large variation
  &.switch-lg {
    font-size: $font-size-lg;

    input {
      + label {
        min-width: calc(#{$switch-height-lg} * 2);
        height: $switch-height-lg;
        line-height: $switch-height-lg;
        text-indent: calc(calc(#{$switch-height-lg} * 2) + .5rem);
      }

      + label::before {
        width: calc(#{$switch-height-lg} * 2);
      }

      + label::after {
        width: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
        height: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
      }

      &:checked + label::after {
        margin-left: $switch-height-lg;
      }
    }
  }

  + .switch {
    margin-left: 1rem;
  }
}

// Callout
.callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  code {
    border-radius: 3px;
  }
  & + .bs-callout {
    margin-top: -5px;
  }
}
@each $name,$color in $colors {
  .callout-#{$name} {
    border-left-color: $color;
    h4 {
      color: $color;
    }
  }
}

//drawer
.modal{

  &.drawer{

    padding-left: 0 !important;

    display: flex !important;

    pointer-events: none;
    * {
      pointer-events: none;
    }

    .modal-dialog{
      margin: 0px;
      display: flex;
      flex: auto;
      transform: translate(25%, 0);
      .modal-content{
        border:none;
        border-radius: 0px;
        .modal-body{
          overflow: auto;
        }
      }

    }

    &.show{
      pointer-events: auto;
      * {
        pointer-events: auto;
      }
      .modal-dialog{
        transform: translate(0, 0);
      }
    }

    &.right-align{
      flex-direction: row-reverse;
    }
    &.left-align{
      &:not(.show){
        .modal-dialog{
          transform: translate(-25%, 0);
        }
      }
    }

  }


}

//sidebar

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-left: 250px;
}

#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    background: $navbar-bg;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: 250px;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
}

.sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: $navbar-fore;
    border-style: solid none none none;
    border-width: .01rem;
    border-color: rgb($gray,.2);
}

.sidebar-nav li a:hover {
    text-decoration: none;
    color: $navbar-hover-fore;
    background: rgba($navbar-hover,0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
    text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
    color: $navbar-fore;
}

.sidebar-nav > .sidebar-brand a:hover {
    color: $navbar-hover-fore;
    background: none;
}

.sidebar-nav div.sub li a{
    text-indent: 40px;
}

.sidebar-nav li a.active {
    background-color: rgb(gray,.3);
    color: $navbar-fore;
}

@media(min-width:768px) {
    #wrapper {
        padding-left: 250px;
    }

    #wrapper.toggled {
        padding-left: 0;
    }

    #sidebar-wrapper {
        width: 250px;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 0;
    }

    #page-content-wrapper {
        padding: 20px;
        position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }
}

.active-state{
  outline: 0;

  -webkit-box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);
  box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);
}

@each $name,$color in $colors {

  .active-state-#{$name}{
    outline: 0;

    -webkit-box-shadow: 0 0 0 0.2rem rgba($color, 0.25);
    box-shadow: 0 0 0 0.2rem rgba($color, 0.25);
  }

}

.active-state-select{
  outline: 0;

  -webkit-box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);
  box-shadow: 0 5px 0px 0.2rem rgba($primary, 0.25);
}

// Disabled
.disabled-ch-mark{
  opacity: .7;
}
.disabled-ch-mark:hover{
  background-color: #eee !important;
}
.disabled-ch:hover .be-ch-checkmark{
  background-color: #eee !important;
}

// Admin card

.card-icon {
  position: absolute !important;
  top: 1.8rem !important;
  right: -0.8rem !important;
  color: white !important;
  opacity: 40% !important;
  font-size: 6rem !important;
  transition: all 0.2s ease-in-out;
}

.AdminCard:hover > .card-icon {
  cursor: pointer;
  position: absolute !important;
  top: 1.4rem !important;
  right: -0.5rem !important;
  color: white !important;
  opacity: 60% !important;
  font-size: 6.1rem !important;
  transition: all 0.2s ease-in-out;
}

.AdminCard:hover {
  cursor: pointer;
  opacity: 90%;
  transform: scale(1.015);
  transition: all 0.2s ease-in-out;
}

.AdminCard-title {
  font-weight: bold !important;
}

.AdminCard {
  opacity: 85%;
  transition: all 0.2s ease-in-out;
}