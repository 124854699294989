.be-ch-countainer {
  position: relative;
  padding-left: 25px;
  padding-top: 1px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: normal !important;
}
.be-ch-countainer ::after, .be-ch-countainer ::before {
  box-sizing: unset !important;
}

.be-ch-countainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.be-ch-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 0.2rem;
  outline: 0;
}

.be-ch-countainer:hover input ~ .be-ch-checkmark {
  background-color: #ccc;
}

.be-ch-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.be-ch-countainer input:checked ~ .be-ch-checkmark:after {
  display: block;
}

.be-ch-countainer .be-ch-checkmark:after {
  left: 7px;
  top: 4px;
  width: 3px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.be-ch-countainer input:checked ~ .be-ch-checkmark-primary {
  background-color: #007bff;
}

.be-ch-countainer input:checked ~ .be-ch-checkmark-danger {
  background-color: #dc3545;
}

.be-ch-countainer input:checked ~ .be-ch-checkmark-warning {
  background-color: #ffc107;
}

.be-ch-countainer input:checked ~ .be-ch-checkmark-info {
  background-color: #17a2b8;
}

.be-ch-countainer input:checked ~ .be-ch-checkmark-success {
  background-color: #28a745;
}

.be-ch-countainer input:checked ~ .be-ch-checkmark-secondary {
  background-color: #6c757d;
}

.be-ra-container {
  display: inline-block;
  position: relative;
  padding-left: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.be-ra-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.be-ra-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

.be-ra-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.be-ra-container input:checked ~ .be-ra-checkmark:after {
  display: block;
}

.be-ra-container .be-ra-checkmark:after {
  top: 7px;
  left: 7px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

.be-ra-container:hover input ~ .be-ra-checkmark {
  background-color: #ccc;
}

.be-ra-container input:checked ~ .be-ra-checkmark-primary {
  background-color: #007bff;
}

.be-ra-container input:checked ~ .be-ra-checkmark-danger {
  background-color: #dc3545;
}

.be-ra-container input:checked ~ .be-ra-checkmark-warning {
  background-color: #ffc107;
}

.be-ra-container input:checked ~ .be-ra-checkmark-info {
  background-color: #17a2b8;
}

.be-ra-container input:checked ~ .be-ra-checkmark-success {
  background-color: #28a745;
}

.be-ra-container input:checked ~ .be-ra-checkmark-secondary {
  background-color: #6c757d;
}

.be-select {
  position: relative;
  display: inline-block;
  line-height: normal !important;
}

.be-select select {
  display: none;
}

.select-selected {
  background-color: white;
  border-radius: 0.2rem;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}
.select-selected:hover {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
}

.select-selected:after {
  position: absolute;
  content: "";
  top: 16px;
  right: 10px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: black transparent transparent transparent;
}

.select-selected.select-arrow-active {
  border-radius: 0.5rem 0.5rem 0 0;
  border-color: white;
}

.select-selected.select-arrow-active:after {
  border-color: transparent transparent black transparent;
  top: 10px;
}

.select-items div, .select-selected {
  color: #000000;
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
}

.select-items {
  position: absolute;
  background-color: white;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border-radius: 0 0 0.5rem 0.5rem;
  border-style: none;
}

.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.05);
}

.blockDis {
  display: block;
}

.switch {
  font-size: 1rem;
  position: relative;
}
.switch input {
  position: absolute;
  height: 1px;
  width: 1px;
  background: none;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  padding: 0;
}
.switch input + label {
  position: relative;
  top: 2;
  min-width: calc(calc(2rem * .8) * 2);
  border-radius: calc(2rem * .8);
  height: calc(2rem * .8);
  line-height: calc(2rem * .8);
  display: inline-block;
  cursor: pointer;
  outline: none;
  user-select: none;
  vertical-align: middle;
  text-indent: calc(calc(calc(2rem * .8) * 2) + .5rem);
}
.switch input + label::before,
.switch input + label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(calc(2rem * .8) * 2);
  bottom: 0;
  display: block;
}
.switch input + label::before {
  right: 0;
  background-color: #dee2e6;
  border-radius: calc(2rem * .8);
  transition: 0.2s all;
}
.switch input + label::after {
  top: 2px;
  left: 2px;
  width: calc(calc(2rem * .8) - calc(2px * 2));
  height: calc(calc(2rem * .8) - calc(2px * 2));
  border-radius: 50%;
  background-color: white;
  transition: 0.2s all;
}
.switch input:checked + label::before {
  background-color: #007bff;
}
.switch input:checked + label::after {
  margin-left: calc(2rem * .8);
}
.switch input:focus + label::before {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.switch input:disabled + label {
  color: #868e96;
  cursor: not-allowed;
}
.switch input:disabled + label::before {
  background-color: #e9ecef;
}
.switch.switch-sm {
  font-size: 0.875rem;
}
.switch.switch-sm input + label {
  min-width: calc(calc(1.9375rem * .8) * 2);
  height: calc(1.9375rem * .8);
  line-height: calc(1.9375rem * .8);
  text-indent: calc(calc(calc(1.9375rem * .8) * 2) + .5rem);
}
.switch.switch-sm input + label::before {
  width: calc(calc(1.9375rem * .8) * 2);
}
.switch.switch-sm input + label::after {
  width: calc(calc(1.9375rem * .8) - calc(2px * 2));
  height: calc(calc(1.9375rem * .8) - calc(2px * 2));
}
.switch.switch-sm input:checked + label::after {
  margin-left: calc(1.9375rem * .8);
}
.switch.switch-lg {
  font-size: 1.25rem;
}
.switch.switch-lg input + label {
  min-width: calc(calc(3rem * .8) * 2);
  height: calc(3rem * .8);
  line-height: calc(3rem * .8);
  text-indent: calc(calc(calc(3rem * .8) * 2) + .5rem);
}
.switch.switch-lg input + label::before {
  width: calc(calc(3rem * .8) * 2);
}
.switch.switch-lg input + label::after {
  width: calc(calc(3rem * .8) - calc(2px * 2));
  height: calc(calc(3rem * .8) - calc(2px * 2));
}
.switch.switch-lg input:checked + label::after {
  margin-left: calc(3rem * .8);
}
.switch + .switch {
  margin-left: 1rem;
}

.callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
}
.callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
}
.callout p:last-child {
  margin-bottom: 0;
}
.callout code {
  border-radius: 3px;
}
.callout + .bs-callout {
  margin-top: -5px;
}

.callout-primary {
  border-left-color: #007bff;
}
.callout-primary h4 {
  color: #007bff;
}

.callout-danger {
  border-left-color: #dc3545;
}
.callout-danger h4 {
  color: #dc3545;
}

.callout-warning {
  border-left-color: #ffc107;
}
.callout-warning h4 {
  color: #ffc107;
}

.callout-info {
  border-left-color: #17a2b8;
}
.callout-info h4 {
  color: #17a2b8;
}

.callout-success {
  border-left-color: #28a745;
}
.callout-success h4 {
  color: #28a745;
}

.callout-secondary {
  border-left-color: #6c757d;
}
.callout-secondary h4 {
  color: #6c757d;
}

.modal.drawer {
  padding-left: 0 !important;
  display: flex !important;
  pointer-events: none;
}
.modal.drawer * {
  pointer-events: none;
}
.modal.drawer .modal-dialog {
  margin: 0px;
  display: flex;
  flex: auto;
  transform: translate(25%, 0);
}
.modal.drawer .modal-dialog .modal-content {
  border: none;
  border-radius: 0px;
}
.modal.drawer .modal-dialog .modal-content .modal-body {
  overflow: auto;
}
.modal.drawer.show {
  pointer-events: auto;
}
.modal.drawer.show * {
  pointer-events: auto;
}
.modal.drawer.show .modal-dialog {
  transform: translate(0, 0);
}
.modal.drawer.right-align {
  flex-direction: row-reverse;
}
.modal.drawer.left-align:not(.show) .modal-dialog {
  transform: translate(-25%, 0);
}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #fafafa;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

/* Sidebar Styles */
.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #000000;
  border-style: solid none none none;
  border-width: 0.01rem;
  border-color: rgba(108, 117, 125, 0.2);
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #000000;
  background: rgba(177, 177, 177, 0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
  color: #000000;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #000000;
  background: none;
}

.sidebar-nav div.sub li a {
  text-indent: 40px;
}

.sidebar-nav li a.active {
  background-color: rgba(128, 128, 128, 0.3);
  color: #000000;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    padding: 20px;
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}
.active-state {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.active-state-primary {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.active-state-danger {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.active-state-warning {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}

.active-state-info {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.25);
}

.active-state-success {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.active-state-secondary {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
}

.active-state-select {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 5px 0px 0.2rem rgba(0, 123, 255, 0.25);
}

.disabled-ch-mark {
  opacity: 0.7;
}

.disabled-ch-mark:hover {
  background-color: #eee !important;
}

.disabled-ch:hover .be-ch-checkmark {
  background-color: #eee !important;
}

.card-icon {
  position: absolute !important;
  top: 1.8rem !important;
  right: -0.8rem !important;
  color: white !important;
  opacity: 40% !important;
  font-size: 6rem !important;
  transition: all 0.2s ease-in-out;
}

.AdminCard:hover > .card-icon {
  cursor: pointer;
  position: absolute !important;
  top: 1.4rem !important;
  right: -0.5rem !important;
  color: white !important;
  opacity: 60% !important;
  font-size: 6.1rem !important;
  transition: all 0.2s ease-in-out;
}

.AdminCard:hover {
  cursor: pointer;
  opacity: 90%;
  transform: scale(1.015);
  transition: all 0.2s ease-in-out;
}

.AdminCard-title {
  font-weight: bold !important;
}

.AdminCard {
  opacity: 85%;
  transition: all 0.2s ease-in-out;
}

